import React from "react";
import "../styles/project.css";
const Projects = () => {
  return (
    <div className="container">
      <div className="section_projects">
        <div className="projectmain_heading">
          <h1 className="heading projects_heading">Portfolio Projects</h1>
          <p className="para">
            I have built various different projects to fit different aspects of
            the client's business. If you want to see more examples of my work
            than the ones showcased in this site,
          </p>
        </div>

        <div className="projects_grid">
          <div className="projects_col1">
            <div className="project_img1"></div>
            <div className="project_description">
              <h1 className="project_heading">Bank App</h1>
              <p className="para">
                Bank app made with javascript where you can login with the
                account do transections, gettings loans, checking interest and
                much more
              </p>
              <a
                href="https://github.com/danielkoucky/Banklist"
                className="main_btn"
              >
                Github
              </a>
            </div>
          </div>
          <div className="projects_col2">
            <div className="project_img2"></div>
            <div className="project_description">
              <h1 className="project_heading">Country Explorer</h1>
              <p className="para">
                A website which provides you the data of a country with his
                neighbour country which you search in a website using javascript
                and public apis
              </p>
              <a
                href="https://github.com/danielkoucky/NeighborCountry-"
                className="main_btn"
              >
                Github
              </a>
            </div>
          </div>
          <div className="projects_col3">
            <div className="project_img3"></div>
            <div className="project_description">
              <h1 className="project_heading">Restaurant</h1>
              <p className="para">
                Restaurant reservation website where you book a reservation
                providing time , date , number of guests information contact
                info.
              </p>
              <a
                href="https://github.com/danielkoucky/Reservation"
                className="main_btn"
              >
                Github
              </a>
            </div>
          </div>
          <div className="projects_col4">
            <div className="project_img4"></div>
            <div className="project_description">
              <h1 className="project_heading">Tourists</h1>
              <p className="para">
                A Tourists website where you can see the discount pricing for a
                tour and see beatiful pictures of nature
              </p>
              <a
                href="https://github.com/danielkoucky/Touristapp"
                className="main_btn"
              >
                Github
              </a>
            </div>
          </div>

          <div className="projects_col6">
            <div className="project_img6"></div>
            <div className="project_description">
              <h1 className="project_heading">Mole Game</h1>
              <p className="para">
                A Whack a mole game where user can play game and get his score
                on leaderboard.
              </p>
              <a
                href="https://github.com/danielkoucky/MoleGame"
                className="main_btn"
              >
                Github
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
