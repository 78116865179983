import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../styles/contact.css";
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_h0ww9s1", "template_8tlbm8t", form.current, {
        publicKey: "a3bb8ljYHK8T9lCNl",
      })
      .then(
        () => {
          alert("You message has been submitted");
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="container">
      <h1 className="heading">Let's Connect </h1>

      <div className="main-div-form">
        <div className="form-main">
          <form ref={form} onSubmit={sendEmail}>
            <div className="main-inputs">
              <input
                type="text"
                name="user_name"
                required
                placeholder="Full name"
              />
              <input
                type="email"
                name="user_email"
                required
                placeholder="Email address"
              />

              <input type="text" required placeholder="Subject" />
            </div>
            <textarea
              name="message"
              placeholder="How can I help you?"
              id=""
              cols="30"
              rows="10"
              required
            ></textarea>
            <div className="form_btn">
              <button className="form_submit_btn">
                <div class="svg-wrapper-1">
                  <div class="svg-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="currentColor"
                        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <span>Send</span>
              </button>
            </div>
          </form>
        </div>
        <div className="main_earth">
          <div id="earth" />
        </div>
      </div>
    </div>
  );
};
export default Contact;
